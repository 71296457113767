import React, { useEffect, useRef, useState } from "react";
import { config } from "../../../../../config/global";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../slice/RootReducer";
import isAuthenticate from "../../../../../utils/hook/isAuthenticate";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import {
  getTripDataFailure,
  getTripDataSuccess,
  tripDataRequest,
} from "../../../slice/tripSlice";
import styles from "./manage-booking.module.scss";
import TGTextField from "../../../../../shared/tg-text-field";
import { Button } from "react-bootstrap";
import { updateFieldData } from "../../../slice/bookingWidgetSlice";
import {
  blockSpecialChar,
  getCookie,
  isvalidAlphabet,
} from "../../../../../utils/helper";
import TGMultiCard from "../../../../../shared/tg-multi-card";
import TGIcon from "../../../../../shared/tg-icon";
import TGButton from "../../../../../shared/tg-button";
import TGInput from "../../../../../shared/tg-input";
import { tripDetails } from "../../../models/models";
import FlightStatusUpcomingTrips from "../flight-status/flight-status-upcoming-trips";

const TextINputField = (ref: any) => {
  const isAuthenticated = isAuthenticate(); //  To check whether user is singed in or not
  let isDesktopView = useScreenSize().deviceSize.width > 768;
  const name = isAuthenticated
    ? sessionStorage.getItem("isROPLoggedIn") === "true"
      ? sessionStorage.getItem("FullName")
      : sessionStorage.getItem("lastName")
    : "";
  const prefilledInput =
    sessionStorage.getItem("isROPLoggedIn") === "true"
      ? name?.split(" ")[name?.split(" ").length - 1].toUpperCase()
      : name;
  const [isTicketNoError, setIsTicketNoError] = useState(false);
  const [ticketNo, setTicketNo] = useState("");
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [lastName, setLastName] = useState(
    isAuthenticated ? prefilledInput : ""
  );
  const [isBookingNoError, setIsBookingNoError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const bookingRegex = new RegExp(
    /^(?=.{6}$)(?![0-9]{6})(?![a-zA-Z]{6})[a-zA-Z0-9]{6}$/
  );
  const ticketRegex = new RegExp(/^[0-9]{13}$/);
  const nameRegex = new RegExp(/^(?!\s*$)[a-zA-Z\s]{2,}$/);
  const [maxLength, setMaxLength] = useState(0);

  const { t } = useTranslation();
  const airportFieldData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.fieldData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      setLastName(prefilledInput);
      setIsLastNameError(true);
    }
    if (/^(0|[1-9][0-9]*)$/.test(ticketNo)) {
      setMaxLength(13);
    } else if (/^[a-zA-z0-9]{0,20}/.test(ticketNo)) {
      setMaxLength(6);
    }
  }, [ticketNo]);

  const handleChangeInput = (newValue: any, label: any) => {
    //This conversion is used to convert the labels send from onChange function to lowerCamelCase format.
    const fieldLabel = label
      .replace(/[^\w\s-]/g, "")
      .split(/[\s-]+/)
      .map((word: any, index: any) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");

    dispatch(updateFieldData({ fieldLabel, newValue }));
  };

  const handleBookingNum = (a: any, label: any) => {
    if (blockSpecialChar(a.target.value)) {
      setTicketNo(a.target.value.toUpperCase());
      setIsBookingNoError(false);
      if (a.target.value.length > 0 && a.target.value != null) {
        setIsTicketNoError(true);
        setTicketNo(a.target.value.toUpperCase());
        handleChangeInput(a, label);
      } else {
        setIsTicketNoError(false);
      }
    }
  };

  const handlelastName = (b: any, label: string) => {
    if (isvalidAlphabet(b.target.value)) {
      setLastName(b.target.value);
      setIsNameError(false);
      if (b.target.value.length > 0 && b.target.value != null) {
        setIsLastNameError(true);
        setLastName(b.target.value);
        handleChangeInput(b, label);
      } else {
        setIsLastNameError(false);
      }
    }
  };

  const handleBooking = () => {
    if (ticketNo.length === 6 || ticketNo.length === 13) {
      if (bookingRegex.test(ticketNo) || ticketRegex.test(ticketNo)) {
        if (nameRegex.test(lastName)) {
          handleManageBooking();
        } else {
          setIsNameError(true);
        }
      } else {
        setIsBookingNoError(true);
      }
    } else {
      setIsBookingNoError(true);
    }
  };

  const handleManageBooking = () => {
    const ticketNumber = ticketNo.toUpperCase();
    const username = lastName.replace(/\s/g, "").toUpperCase();
    const language: any = getCookie("config");
    const manageBookingUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.MANAGE_BOOKING_API;
    const lang = language?.languageCode?.toLowerCase();
    const manageBookingQueryParams =
      "booking_no=" + ticketNumber + "&lastname=" + username + "&lang=" + lang;

    const amadeusRedirectionUrl =
      manageBookingUrl + "?" + manageBookingQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  return (
    <>
      {isDesktopView ? (
        <>
          <div>
            <TGTextField
              label={t("label_manage_booking_reference")}
              placeholderText={t("label_manage_booking_reference")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handleBookingNum(e, label)}
              focus={true}
              value={ticketNo || ""}
              maxlength={maxLength}
              isError={isBookingNoError}
              errorLabel={t("label_manage_example_error")}
            />
            <ul className={styles.customTextColor}>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_booking_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_ticket_example")}
              </li>
            </ul>
          </div>
          <div>
            <TGTextField
              label={t("label_manage_last_name")}
              placeholderText={t("label_manage_last_name")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handlelastName(e, label)}
              value={lastName || ""}
              maxlength={40}
              isError={isNameError}
              errorLabel={t("label_manage_last_name_error")}
            />
          </div>
        </>
      ) : (
        <div>
          <div>
            <TGTextField
              label={t("label_manage_booking_reference")}
              placeholderText={t("label_manage_booking_reference")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handleBookingNum(e, label)}
              focus={true}
              value={ticketNo || ""}
              maxlength={maxLength}
              isError={isBookingNoError}
              errorLabel={t("label_manage_example_error")}
            />
            <ul className={styles.customTextColor}>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_booking_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_ticket_example")}
              </li>
            </ul>
          </div>
          <div>
            <TGTextField
              label={t("label_manage_last_name")}
              placeholderText={t("label_manage_last_name")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handlelastName(e, label)}
              value={lastName || ""}
              maxlength={40}
              isError={isNameError}
              errorLabel={t("label_manage_last_name_error")}
            />
          </div>
        </div>
      )}

      {!isDesktopView ? (
        <div
          className={
            isAuthenticated
              ? styles.buttonWidgetAllignmentMobile
              : styles.buttonMobile
          }
        >
          <TGButton
            label={t("label_book_widget_manage_booking")}
            variant="primary"
            buttonSize="lg"
            customClass="bookingButtonCheckIn"
            onClick={handleBooking}
            disabled={!isTicketNoError || !isLastNameError}
            aria-label="Manage Booking button"
          />
        </div>
      ) : (
        <div>
          <Button
            className={styles.buttonContainer}
            onClick={handleBooking}
            disabled={!isTicketNoError || !isLastNameError}
            aria-label="Manage Booking button"
          >
            <span> {t("label_book_widget_manage_booking")}</span>
          </Button>
        </div>
      )}
    </>
  );
};

const ManageBooking = () => {
  const { i18n, t } = useTranslation();
  const additionalServices = [
    {
      serviceId: 1,
      serviceURL:
        process.env.REACT_APP_TG_TEAMSITE_SEARCH_URL +
        i18n.language.replace("-", "_") +
        process.env.REACT_APP_ADDITIONAL_BAGGAGE,
      serviceName: t("label_additional_baggage_button"),
      seriveIcon: "../assets/AdditionalBaggage.png",
    },
    {
      serviceId: 2,
      serviceURL:
        process.env.REACT_APP_TG_TEAMSITE_SEARCH_URL +
        i18n.language.replace("-", "_") +
        process.env.REACT_APP_PREFERRED_SEATS,
      serviceName: t("label_preferred_Seats_Button"),
      seriveIcon: "../assets/PreferredSeats.png",
    },
    {
      serviceId: 3,
      serviceURL:
        process.env.REACT_APP_TG_TEAMSITE_SEARCH_URL +
        "" +
        process.env.REACT_APP_PREFERRED_MEALS,
      serviceName: t("label_preSelect_Meals_Button"),
      seriveIcon: "../assets/Pre-SelectMeals.png",
    },
    {
      serviceId: 4,
      serviceURL: process.env.REACT_APP_ELIBRARY,
      //need to update URL in .env
      // serviceURL:
      //   process.env.REACT_APP_TG_TEAMSITE_SEARCH_URL +
      //   i18n.language.replace("-", "_") +
      //   process.env.REACT_APP_ELIBRARY,
      serviceName: t("label_eLibrary_button"),
      seriveIcon: "../assets/E-Library.png",
    },
  ];

  const renderAdditionalServices = () => {
    return (
      <>
        <div className={styles.MMBAdditionalservices}>
          <div className={styles.MMBAdditionalserviceHeading}>
            {t("label_additional_services_heading")}
          </div>
          <div className={styles.MMBCardsContainer}>
            {additionalServices.map((item: any, index: any) => (
              <Link
                to={item.serviceURL}
                target={"_blank"}
                aria-label={item.serviceName}
                className={styles.MMBadditionalBaggage}
              >
                <div className={styles.MMBMIconContainer}>
                  <img
                    src={item.seriveIcon}
                    alt={item.serviceName}
                    className={
                      item.serviceId === 1
                        ? styles.MMBadditionalBaggageIcon
                        : item.serviceId === 2
                        ? styles.MMBPreferredSeatsIcon
                        : item.serviceId === 3
                        ? styles.MMBMealsIcon
                        : styles.MMBStandbyUpgradeIcon
                    }
                  />
                </div>
                <div className={styles.MMBadditionalBaggageText}>
                  {item.serviceName}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </>
    );
  };

  const airportFieldData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.fieldData
  );
  // todo: this has to convert to api call to fetch upcoming trips
  const trips: tripDetails = useSelector(
    (state: RootState) => state?.osciReducer?.trips?.upcomingTrips
  );
  const dispatch = useDispatch();
  const isAuthenticated = isAuthenticate(); //  To check whether user is singed in or not
  const MANAGE_BOOKING_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
  let isDesktopView = useScreenSize().deviceSize.width > 768;
  const isTrips = useState(true);
  const [isRetrieveBooking, setIsRetrieveBooking] = useState(false);
  const TripData = trips?.pnrDetails?.map((resp: { flightDetails: any }) => {
    return resp.flightDetails;
  });
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(tripDataRequest());
    }
  }, [MANAGE_BOOKING_API_URL, dispatch, isAuthenticated]);

  function NoupcomingTrips() {
    return (
      <div
        className={
          isDesktopView ? styles.imageContainer : styles.imageContainerMobile
        }
      >
        <img src="/assets/noUpcomingTrips.svg" alt="No Upcoming Trips" />
        <p className={styles.imgText}>{t("label_no_upcoming_trips")}</p>
      </div>
    );
  }

  function toggleRetrieveBooking() {
    setIsRetrieveBooking(!isRetrieveBooking);
  }

  return (
    <>
      {isDesktopView ? (
        isAuthenticated ? (
          <>
            <div className={styles.upcomingContainer}>
              {TripData && TripData.length > 0 ? (
                <>
                  <p className={styles.upcomingHeading}>
                    {t("label_upcomingtrips_header")}
                  </p>
                  <FlightStatusUpcomingTrips isMiniProfile={false} page="MB" />
                  {isTrips &&
                    (isRetrieveBooking ? (
                      <div className={styles.retrieveBookingContainer}>
                        <div className={styles.retrieveBookingHeader}>
                          <span className={styles.upcomingHeading}>
                            {t("label_retrievebooking_header")}
                          </span>
                          <button
                            onClick={toggleRetrieveBooking}
                            className={styles.buttonRadius}
                            aria-label="close-button"
                          >
                            <TGIcon icon="promo-close" fillColor="" size="" />
                          </button>
                        </div>
                        <div className={styles.flexContainer}>
                          <TextINputField />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styles.addRetrieveContainer}
                        onClick={toggleRetrieveBooking}
                      >
                        <img src={"./assets/Add.svg"} alt="Add Booking" />
                        <span className={styles.upcomingHeading}>
                          {t("label_retrievebooking_header")}
                        </span>
                      </div>
                    ))}
                  <div className={styles.MMBAdditionalservices}>
                    {renderAdditionalServices()}
                  </div>
                </>
              ) : (
                <>
                  <NoupcomingTrips />
                  {isTrips &&
                    (isRetrieveBooking ? (
                      <div className={styles.retrieveBookingContainer}>
                        <div className={styles.retrieveBookingHeader}>
                          <span className={styles.upcomingHeading}>
                            {t("label_retrievebooking_header")}
                          </span>
                          <button
                            onClick={toggleRetrieveBooking}
                            className={styles.buttonRadius}
                            aria-label="close-button"
                          >
                            <TGIcon icon="promo-close" fillColor="" size="" />
                          </button>
                        </div>
                        <div className={styles.flexContainer}>
                          <TextINputField />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styles.addRetrieveContainer}
                        onClick={toggleRetrieveBooking}
                      >
                        <img src={"./assets/Add.svg"} alt="Add Booking" />
                        <span className={styles.upcomingHeading}>
                          {t("label_retrievebooking_header")}
                        </span>
                      </div>
                    ))}
                  <div className={styles.MMBAdditionalservices}>
                    {renderAdditionalServices()}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.flexContainer}>
              <TextINputField />
            </div>
            <div className={styles.MMBAdditionalservices}>
              {renderAdditionalServices()}
            </div>
          </>
        )
      ) : isAuthenticated ? (
        <>
          <div className={styles.mobileContainer}>
            {TripData && TripData.length > 0 ? (
              <>
                <p
                  className={`${styles.upcomingHeading} ${styles.upcomingtripsHeader}`}
                >
                  {t("label_upcomingtrips_header")}
                </p>
                <FlightStatusUpcomingTrips isMiniProfile={false} page="MB" />
                {isTrips &&
                  (isRetrieveBooking ? (
                    <div className={styles.flexContainerMobile}>
                      <div className={styles.retrieveBookingHeader}>
                        <span className={styles.upcomingHeading}>
                          {t("label_retrievebooking_header")}
                        </span>
                        <button
                          onClick={toggleRetrieveBooking}
                          className={styles.buttonRadius}
                          aria-label="close-button"
                        >
                          <TGIcon icon="promo-close" fillColor="" size="" />
                        </button>
                      </div>
                      <TextINputField />
                    </div>
                  ) : (
                    <div
                      className={styles.addRetrieveContainer}
                      onClick={toggleRetrieveBooking}
                    >
                      <img src={"./assets/Add.svg"} alt="Add Booking" />
                      <span className={styles.upcomingHeading}>
                        {t("label_retrievebooking_header")}
                      </span>
                    </div>
                  ))}
                <div className={styles.MMBAdditionalservices}>
                  {renderAdditionalServices()}
                </div>
              </>
            ) : (
              <div className={styles.mobileContainer}>
                <NoupcomingTrips />
                {isTrips &&
                  (isRetrieveBooking ? (
                    <div className={styles.flexContainerMobile}>
                      <div className={styles.retrieveBookingHeader}>
                        <span className={styles.upcomingHeading}>
                          {t("label_retrievebooking_header")}
                        </span>
                        <button
                          onClick={toggleRetrieveBooking}
                          className={styles.buttonRadius}
                          aria-label="close-button"
                        >
                          <TGIcon icon="promo-close" fillColor="" size="" />
                        </button>
                      </div>
                      <TextINputField />
                    </div>
                  ) : (
                    <div
                      className={styles.addRetrieveContainer}
                      onClick={toggleRetrieveBooking}
                    >
                      <img src={"./assets/Add.svg"} alt="Add Booking" />
                      <span className={styles.upcomingHeading}>
                        {t("label_retrievebooking_header")}
                      </span>
                    </div>
                  ))}
                <div className={styles.MMBAdditionalservices}>
                  {renderAdditionalServices()}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.flexContainerMobileNotAuthenticate}>
            <TextINputField />
          </div>
          <div className={styles.MMBAdditionalservices}>
            {renderAdditionalServices()}
          </div>
        </>
      )}
    </>
  );
};

export default ManageBooking;
