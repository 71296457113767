import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { CategoryItem, GroupItem, MoreService } from "../../../models/models";
import styles from "./more-services.module.scss";
import TGIcon from "../../../../../shared/tg-icon";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { config } from "../../../../../config/global";
import {
  getMoreServicesDataFailure,
  getMoreServicesDataSuccess,
} from "../../../slice/moreServicesSlice";
import { useOutsideClick } from "../../../../../utils/hook/useClickOutside";

const MoreServices: React.FC = () => {
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;

  const dispatch = useDispatch();

  const [openCategory, setOpenCategory] = useState<{
    [key: string]: boolean;
  }>({});
  const [openCategoryId, setOpenCategoryId] = useState<string | null>(null);
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);

  const categoryRef = useRef<any>([]);

  const data = useSelector((state: RootState) => state?.osciReducer?.moreServices);

  const toggleCategory = (categoryId: any, e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setOpenCategoryId((prev) => (prev === categoryId ? null : categoryId));

    setOpenCategory((prevState) => ({
      ...Object?.keys(prevState)?.reduce((acc: any, key: any) => {
        acc[key] = false;
        return acc;
      }, {}),
      [categoryId]: !prevState[categoryId],
    }));
  };

  const handleClickOutside = () => {
    setOpenCategoryId(null);
    setOpenCategory({});
  };

  const autoRef: any = useOutsideClick(handleClickOutside);

  // Effect to update dropdown width on resize
  useEffect(() => {
    const updateDropdownWidth = () => {
      if (openCategoryId && categoryRef?.current) {
        const width = categoryRef?.current?.offsetWidth; // Get width of the category
        setDropdownWidth(width); // Set width for dropdown
      }
    };

    window.addEventListener("resize", updateDropdownWidth);
    updateDropdownWidth();
    return () => window.removeEventListener("resize", updateDropdownWidth);
  }, [openCategoryId]);

  // Dispatch more services data request
  useEffect(() => {
    const API_URL = config.TEAMSITE_MORE_SERVICES_API;
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: API_URL,
      isTeamsite: true,
      successAction: getMoreServicesDataSuccess,
      errorAction: getMoreServicesDataFailure,
    });
  }, [dispatch]);

  // Function to render category items
  const CategoryItem = ({ category }: any) => (
    <>
      {category.categoryItems.map((categoryItem: CategoryItem) => (
        <div key={categoryItem?.serviceId}>
          <div className={styles.catergoryList}>
            <Link
              key={categoryItem?.serviceId}
              to={categoryItem.serviceRedURL}
              target={categoryItem.isExternalUrl ? "_blank" : "_self"}
              aria-label={categoryItem.serviceName}
              className={styles.categoryLinkContainer}
            >
              <span className={styles.serviceName}>
                {categoryItem.serviceName}
              </span>
              <>
                {categoryItem.isExternalUrl && (
                  <TGIcon fillColor="#6A6D87" icon="external-link" size="14" />
                )}
              </>
            </Link>
          </div>
        </div>
      ))}
    </>
  );

  // Function to render category items for mobile view
  const CategoryItemMobile = ({ category }: any) => (
    <>
      {category.categoryItems.map((categoryItem: CategoryItem) => (
        <div
          key={categoryItem?.serviceId}
          className={styles.catergoryListContainer}
        >
          <div className={styles.catergoryListMobile}>
            <Link
              key={categoryItem?.serviceId}
              to={categoryItem.serviceRedURL}
              target={categoryItem.isExternalUrl ? "_blank" : "_self"}
              aria-label={categoryItem.serviceName}
              className={styles.categoryLinkContainer}
            >
              <span className={styles.serviceName}>
                {categoryItem.serviceName}
              </span>
              <>
                {categoryItem.isExternalUrl && (
                  <TGIcon fillColor="#6A6D87" icon="external-link" size="14" />
                )}
              </>
            </Link>
          </div>
        </div>
      ))}
    </>
  );

  // Function to render category items
  const renderCategoryItems = (category: GroupItem) => {
    if (openCategory[category?.categoryId]) {
      return (
        <div
          ref={autoRef}
          className={`${styles.categoryListGroup} booking-widget-overlay`}
          style={{ width: `${dropdownWidth}px` }}
        >
          <CategoryItem category={category} />
        </div>
      );
    }
  };

  // Function to render the plus/minus icon
  const renderIcon = (categoryId: any) => {
    const isOpen = openCategory[categoryId];
    return (
      <div className={styles.toggleIcon}>
        {isOpen ? (
          <TGIcon icon="minus-icon" size="24px" fillColor=""></TGIcon>
        ) : (
          <TGIcon icon="plus-icon" size="24px" fillColor=""></TGIcon>
        )}
      </div>
    );
  };

  // Function to render a title category
  const renderCategory = (category: GroupItem) => (
    <div key={category?.categoryId} className={styles.categoryContainer}>
      <div
        key={category?.categoryId}
        className={styles.categoryTitle}
        aria-label={category?.categoryTitle}
        onClick={(e) => toggleCategory(category.categoryId, e)}
        role="button"
        tabIndex={0}
      >
        {category?.categoryTitle}
      </div>
      {isDesktopView && (
        <span
          onClick={(e) => toggleCategory(category.categoryId, e)}
          className={styles.icon}
          role="button"
          aria-label="expand-icon"
          tabIndex={0}
        >
          {renderIcon(category?.categoryId)}
        </span>
      )}
      {renderCategoryItems(category)}
    </div>
  );

  // Main render content function
  const RenderContent = () => (
    <>
      {data?.moreServicesDetails?.moreServices?.map(
        (group: MoreService, groupIndex: number) => (
          <div
            ref={categoryRef}
            key={group.groupId}
            className={styles.groupContainer}
          >
            {group?.groupItems?.map(renderCategory)}
            {groupIndex <
              data?.moreServicesDetails?.moreServices?.length - 1 && (
              <img
                src={"/assets/Separator.svg"}
                alt={"separator"}
                className={styles.separator}
              />
            )}
          </div>
        )
      )}
    </>
  );

  // Main render content function for mobile view
  const RenderMobileContent = () => (
    <>
      {data?.moreServicesDetails?.moreServices?.map(
        (group: MoreService, groupIndex: number) => (
          <div key={group.groupId} className={styles.mobileContainer}>
            {group?.groupItems?.map((category: GroupItem) => (
              <div
                key={category?.categoryId}
                className={styles.mobileTitleContainer}
              >
                <div
                  key={category?.categoryId}
                  className={styles.mobileWrapper}
                >
                  <div
                    key={category?.categoryId}
                    className={styles.categoryTitle}
                    aria-label={category?.categoryTitle}
                    role="button"
                    tabIndex={0}
                  >
                    {category?.categoryTitle}
                  </div>
                </div>
                <CategoryItemMobile category={category} />
              </div>
            ))}
          </div>
        )
      )}
    </>
  );

  // Check if there are less than 3 items in the data
  const moreServicesCount =
    data?.moreServicesDetails?.moreServices?.length || 0;

  // Conditionally render only if there are 3 or more items
  if (isDesktopView && moreServicesCount < 3) {
    return null; // Don't render if there are less than 3 times
  }

  return (
    <>
      {isDesktopView && (
        <div
          className={`${styles.moreServicesContainer} ${
            openCategoryId && styles.moreServicesCustom
          } global-content-padding`}
        >
          <div className={styles.moreServicesWrapper}>
            <RenderContent />
          </div>
        </div>
      )}
      {!isDesktopView && <RenderMobileContent />}
    </>
  );
};

export default MoreServices;
