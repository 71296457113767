import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, FormGroup, Offcanvas } from "react-bootstrap";
import styles from "./multi-city-autosuggest.module.scss";
import { useOutsideClick } from "../../../../../../utils/hook/useClickOutside";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import TGIcon from "../../../../../../shared/tg-icon";
import TGPills from "../../../../../../shared/tg-pills";
import { t } from "i18next";
import { isvalidAlphabet, setLocalStorageData } from "../../../../../../utils/helper";

export interface Props {
  label: string;
  customClassName?: string;
  onChange?: (e: any, label: string) => void;
  onSelect?: (e: any, name: string, index: number) => void;
  onClear?: (index: number) => void;
  searchPlaceHolder?: string;
  name: string;
  selectMenuBar?: string;
  options: Array<any>;
  id?: string;
  backdropClass?: any;
  offcanvasClass?: any;
  rowIndex: number;
  selectedPropsValue: any;
  reverseArrow?: boolean;
  onSwap?: Function;
  recentAirport: Array<any>;
  onClearRecentSearch: (s: string) => void;
  isFocused: boolean;
  onAutoSuggestBlur: any;
  listHeader?: string;
  componentName?: string;
  view?:boolean;
}

const MultiCityAutosuggest = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef: any = useRef(null);
  const mainRef: any = useRef(null);
  const searchRef: any = useRef(null);
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const [listItem, setListItem] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (props.options && props.options?.length > 0) {
      setListItem(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    if (props.selectedPropsValue) {
      setSelectedValue(props.selectedPropsValue);
    }
  }, [props.selectedPropsValue]);

  useEffect(() => {
    if (props.isFocused) {
      setIsOpen(true);
      setTimeout(() => {
        (searchText || selectedValue) &&
          inputRef.current &&
          inputRef?.current?.select();
      }, 100);
    } else setIsOpen(false);
  }, [props.isFocused]);

  const handleClickOutside = () => {
    // if(!isMobile){
    //   const ele: any = document?.activeElement;
    //   console.log("element blur", ele?.tagName?.toLowerCase(), ele?.id)
    //   if(ele.id!==`input-container-${props.name}` || ele.id!==`input-box-from-${props.name}`){
    //     setIsOpen(false);
    //   }
    // }
  };
  const autoRef: any = useOutsideClick(handleClickOutside);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--overlay-multicity-width",
      `${2 * autoRef?.current?.offsetWidth}px`
    );
  }, [autoRef?.current?.offsetWidth]);

  const handleSelection = (x: any) => {
    setSelectedValue(x);
    setTimeout(() => {
      setIsOpen(false);
      setListItem(props?.options);
    }, 20);
    setSearchText("");
    if (props.onSelect) {
      props.onSelect(x, props?.name, props?.rowIndex);
    }
  };

  const handleSearchChange = (e: any) => {
    const searchVal = e.target.value;
    setSearchText(searchVal);
    setSelectedValue({});
    if (searchVal === "") {
      setListItem(props?.options);
      return;
    }
    const filterBySearch: Array<any> = props.options?.filter((item: any) => {
      if (
        item.airportName.toLowerCase().includes(searchVal?.toLowerCase()) ||
        item.airportCode.toLowerCase().includes(searchVal?.toLowerCase()) ||
        item.countryCode.toLowerCase().includes(searchVal?.toLowerCase()) ||
        item.countryName.toLowerCase().includes(searchVal?.toLowerCase()) ||
        item.cityName.toLowerCase().includes(searchVal?.toLowerCase())
      ) {
        return item;
      }
    });
    setListItem(filterBySearch);
  };

  const handleClear = (e:any,option:any,type:string) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === "ALL") {
      if (props.name === "from") {
        setLocalStorageData("recentFromAirport", []);
        props.onClearRecentSearch("from");
      } else if (props.name === "to") {
        setLocalStorageData("recentToAirport", []);
        props.onClearRecentSearch("to");
      }
    } else if(type === "single") {
      const updatedOptions = props?.recentAirport?.filter(
        (options: any) => options.airportCode !== option.airportCode
      );
      console.log(updatedOptions,"test100");

      if (props.name === "from") {
        setLocalStorageData("recentFromAirport", updatedOptions);
        props.onClearRecentSearch("from");
      }else if (props.name === "to") {
        setLocalStorageData("recentToAirport", updatedOptions);
        props.onClearRecentSearch("to");
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if(!isvalidAlphabet(e?.key)){
      e?.preventDefault();
    }
  }

  const renderChild = () => {
    return (
      <div className={styles["multi-city-wrapper"]}>
        {searchText?.length === 0 && props?.recentAirport?.length > 0 && (
          <>
            <div className={styles["multi-city-header"]}>
              <div>{t("label_book_widget_recent_airports")}</div>
              <div
                className={styles["tg-autosuggest-clear-search"]}
                onClick={(e) => handleClear(e, {}, "ALL")}
              >
                <div>{t("label_clear_all")}</div>
              </div>
            </div>
            <div
              className={`${styles["multi-city-recent-airport"]} d-flex flex-column`}
              tabIndex={0}
            >
              {props.recentAirport?.map((x, indexVal) => (
                <div
                  onClick={() => handleSelection(x)}
                  tabIndex={0}
                  className={styles["multi-city-box"]}
                  key={indexVal}
                >
                  <div
                    className={`${styles["multi-city-inner-wrapper"]} ${styles["recent-search"]}`}
                    tabIndex={0}
                  >
                    <div>
                      <TGPills
                        label={x.airportCode}
                        variant="primary"
                        bg="#F5EFFF"
                        color=""
                        customClass="autosuggest-pills"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "16px",
                        }}
                      >
                        <div className={styles["icon-wrapper"]}
                        >
                          <span className={styles["multi-city-item-name"]}>
                            {x.cityName}, {x.countryName}
                          </span>
                          {x.tgOperated && (
                            <span className={styles["icons"]}>
                                <TGIcon icon="flight-tail-icon" fillColor="" />
                            </span>
                          )}
                          {x.railStation && (
                            <span className={styles["icons"]}>
                                <TGIcon icon="train-icon" fillColor="" />
                            </span>
                          )}
                        </div>
                        <span className={styles["multi-city-sub-item-name"]}>
                          {x.airportName}
                        </span>
                      </div>
                    </div>
                    <div
                      className={styles["tg-autosuggest-clear-search"]}
                      onClick={(e) => handleClear(e, x, "single")}
                    >
                      <TGIcon icon="close-icon" fillColor="" size="20" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {listItem?.length > 0 ? (
          <>
            <div className={styles["multi-city-header"]}>
              {props?.listHeader || t("label_book_widget_all_airports")}
            </div>
            <div className="d-flex flex-column">
              {listItem?.map((x: any, listIndex: number) => (
                <div
                  onClick={() => handleSelection(x)}
                  className={styles["multi-city-box"]}
                  key={listIndex}
                >
                  <div className={styles["multi-city-inner-wrapper"]}>
                    <TGPills
                      label={x.airportCode}
                      variant="primary"
                      bg="#F5EFFF"
                      color=""
                      customClass="autosuggest-pills"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "16px",
                      }}
                    >
                      <div className={styles["icon-wrapper"]}>
                        <span className={styles["multi-city-item-name"]}>
                          {x.cityName}, {x.countryName}
                        </span>
                        {x.tgOperated && (
                          <span className={styles["icons"]}>
                              <TGIcon icon="flight-tail-icon" fillColor="" />
                          </span>
                        )}
                        {x.railStation && (
                          <span className={styles["icons"]}>
                              <TGIcon icon="train-icon" fillColor="" />
                          </span>
                        )}
                      </div>
                      <span className={styles["multi-city-sub-item-name"]}>
                        {x.airportName}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={styles["multi-city-no-result"]}>
            {t("label_book_widget_not_found")}
          </div>
        )}
      </div>
    );
  };

  const renderMenu = () => {
    if (isMobile) {
      setTimeout(() => {
        searchRef?.current?.focus();
      }, 200);
      return (
        <Offcanvas
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
            props.onAutoSuggestBlur();
          }}
          onEntered={() => searchRef?.current?.focus()}
          placement="bottom"
          className={`${styles["tg-select-offcanvas"]} ${props.offcanvasClass}`}
          backdropClassName={props.backdropClass}
        >
          <Offcanvas.Header
            closeButton
            className={styles["tg-select-offcanvas-header"]}
          >
            <Offcanvas.Title className={styles["tg-select-offcanvas-title"]}>
              {props.label}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className={`${styles["tg-select-search-container"]}`}>
            <div className={styles["tg-select-search-icon"]}>
              <TGIcon icon="search-icon" fillColor="" size="24px" />
            </div>
            <FormGroup controlId="formAirport" className="w-100">
              <Form.Control
                className={`${styles["tg-select-search-box"]}`}
                type="text"
                onChange={(e) => handleSearchChange(e)}
                placeholder={props.searchPlaceHolder}
                value={searchText}
                ref={searchRef}
              />
            </FormGroup>
            {searchText.length > 0 && (
              <div
                className={styles["tg-select-close-icon"]}
                onClick={() => {
                  setSearchText("");
                  setListItem(props.options);
                }}
              >
                <TGIcon icon="close-icon" fillColor="" size="24px" />
              </div>
            )}
          </div>
          <Offcanvas.Body className={styles["multi-city-body-wrapper"]}>
            {renderChild()}
          </Offcanvas.Body>
        </Offcanvas>
      );
    } else if (!isMobile) {
      // document.documentElement.style.setProperty("--everymundo-z-index", "-1");
      return (
        <Dropdown
          onToggle={() => {
            setIsOpen(false);
            props.onAutoSuggestBlur();
            // document.documentElement.style.setProperty(
            //   "--everymundo-z-index",
            //   "unset"
            // );
          }}
          autoClose="outside"
          className="booking-widget-overlay"
        >
          <Dropdown.Menu
            show={isOpen}
            className={`${styles["tg-select-menubar"]} ${props?.id === "redemptionFrom" && styles["redemption-layer"]} ${props.selectMenuBar}`}
          >
            <Dropdown.Item>{renderChild()}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  const renderCustomInput = () => {
    return (
      <div className={isMobile ? "w-100" : "w-50"} ref={autoRef}>
        <div className={`${styles["multi-city-auto-suggest-container"]}`}>
          <div
            className={`${styles["tg-date-picker-container"]} ${
              styles[props.customClassName ? props.customClassName : ""]
            } ${isOpen && styles["tg-date-picker-container-focused"]}
            ${
              isOpen &&
              Object.keys(selectedValue)?.length > 0 &&
              styles["main-outerwrapper"]
            } input-search-field`}
            onClick={(e: any) => {
              setIsOpen(true);
              // !isMobile && e?.target?.scrollIntoViewIfNeeded({block: "center"});
              inputRef.current && inputRef?.current.focus();
              setTimeout(() => {
                (searchText || selectedValue) &&
                  inputRef.current &&
                  inputRef?.current?.select();
              }, 100);
            }}
            title={props.label}
            tabIndex={0}
            ref={mainRef}
            id={`input-container-${props.name}`}
          >
            <div
              className={`${
                isOpen &&
                Object.keys(selectedValue)?.length > 0 &&
                styles["main-wrapper"]
              } ${styles["main-wrapper-default"]}
                ${styles[props.componentName == 'FSTo' && props.view ? "flightScheduleToClass" : ""]} 
              `}
            >
              <p
                className={`${styles["tg-datepicker-label"]} ${
                  (isOpen || Object.keys(selectedValue)?.length > 0) &&
                  styles["tg-datepicker-label-focused"]
                }`}
              >
                {props.label}
              </p>
              {isOpen ? (
                <div className="d-flex gap-2">
                  {selectedValue?.airportCode && (
                    <TGPills
                      label={selectedValue?.airportCode}
                      variant="primary"
                      bg="#F5EFFF"
                      color=""
                      customClass="autosuggest-pills"
                    />
                  )}
                  <input
                    type="text"
                    ref={inputRef}
                    id={`input-box-${props.name}`}
                    name={props.label}
                    className={styles["multi-city-input"]}
                    autoFocus={isOpen}
                    onChange={handleSearchChange}
                    value={searchText || selectedValue?.cityName}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              ) : (
                Object.keys(selectedValue)?.length > 0 && (
                  <div className={styles["multi-city-value"]}>
                    <span>
                      <TGPills
                        label={selectedValue?.airportCode}
                        variant="primary"
                        bg="#F5EFFF"
                        color=""
                        customClass="autosuggest-pills"
                      />
                    </span>

                    <span>{selectedValue?.cityName}</span>
                  </div>
                )
              )}
            </div>
            {Object.keys(selectedValue)?.length > 0 && (
              <div
                onClick={() => {
                  setSelectedValue({});
                  if (props.onClear) {
                    props.onClear(props.rowIndex);
                  }
                }}
                className={`${styles["close-icon"]} ${
                  styles[props.componentName == 'FSFrom' && props.view ? "flightScheduleFromClass" : ""]
                }`}
              >
                <TGIcon icon="close-icon" fillColor="" size="18" />
              </div>
            )}
          </div>
          {isOpen && renderMenu()}
        </div>
        {props.reverseArrow && (
          <span
            className={`${styles["reverse-arrow"]} reverse-swap-button`}
            onClick={() => {
              if (props.onSwap) {
                props.onSwap(props.rowIndex);
              }
            }}
          >
            {isMobile ? (
              <TGIcon icon="double-arrow" fillColor="none" size="" />
            ) : (
              <TGIcon icon="reverse-arrow" fillColor="none" size="" />
            )}
          </span>
        )}
      </div>
    );
  };

  return <>{renderCustomInput()}</>;
};

export default MultiCityAutosuggest;
