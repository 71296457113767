import React, { useEffect, useRef, useState } from "react";
import styles from "./multi-city.module.scss";
import { AirportData, MultiCityFlightProps } from "../../../models/models";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import TGDateCalendar from "../../../../../shared/tg-new-datepicker";
import TGIconLabel from "../../../../../shared/tg-icon-label";
import MultiCityAutosuggest from "./multi-city-autosuggest";
import {
  getLocalStorageData,
  isEmptyChecker,
  setLocalStorageData,
} from "../../../../../utils/helper";
import moment from "moment";
import PassengerClass from "../../passenger-class";
import TGButton from "../../../../../shared/tg-button";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import {
  updateSelectedCardData,
  updateSelectedCardDataMobile,
} from "../../../slice/bookingWidgetSlice";
import { CabinClass } from "../../../../../utils/enum-helper";

const MultiCity: React.FC<MultiCityFlightProps> = (
  props: MultiCityFlightProps
) => {
  const { t, i18n } = useTranslation();
  const addFlightRef: any = useRef(null);
  const [isPassengerFocused, setIsPassengerFocused] = useState(false);
  const [isAddFlightFocused, setAddFlightFocused] = useState(false);
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const recentSearchedData: any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget
  );
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any>([
    {
      id: "flight1",
      from: {},
      to: {},
      date: "",
    },
    {
      id: "flight2",
      from: {},
      to: {},
      date: "",
    },
  ]);
  const [rowsFocused, setRowsFocused] = useState([
    {
      id: "flight1",
      from: false,
      to: false,
      date: false,
    },
    {
      id: "flight2",
      from: false,
      to: false,
      date: false,
    },
  ]);
  const [recentAirports, setRecentAirports] = useState({
    fromAirport: [],
    toAirport: [],
  });
  const [passengerCount, setPassengerCount] = useState<any>({
    adult: 1,
    youth: 0,
    child: 0,
    infant: 0,
  });

  const [classValue, setClassValue] = useState<any>(
    t("label_economy_and_economy_plus")
  );
  const airportData: AirportData | any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const recentFromData = getLocalStorageData("recentFromAirport");
  const recentToData = getLocalStorageData("recentToAirport");

  useEffect(() => {
    let fromData = [];
    let toData = [];
    if (recentFromData && Object.keys(recentFromData)?.length > 0) {
      fromData = JSON.parse(recentFromData);
    }
    if (recentToData && Object.keys(recentToData)?.length > 0) {
      toData = JSON.parse(recentToData);
    }
    setRecentAirports({
      fromAirport: fromData,
      toAirport: toData,
    });
  }, [recentFromData, recentToData]);

  useEffect(() => {
    const timeoutTime = isMobile ? 500 : 200;
    if (
      isAddFlightFocused &&
      rows?.every(
        (x: any) =>
          Object?.keys(x?.from).length > 0 &&
          Object?.keys(x?.to)?.length > 0 &&
          x?.date !== ""
      )
    ) {
      setTimeout(() => {
        addFlightRef?.current?.focus();
      }, timeoutTime);
    }
  }, [isAddFlightFocused, rows, rowsFocused]);

  useEffect(() => {
    if (
      recentSearchedData?.selectedRecentSearchData &&
      !isMobile &&
      recentSearchedData?.selectedRecentSearchData?.routes?.length > 0
    ) {
      const updatedRecentSearchData =
        recentSearchedData?.selectedRecentSearchData?.routes?.map((x: any) => {
          return {
            ...x,
            date:
              new Date(moment(x?.date).format("YYYY-MM-DD"))?.getTime() >=
              new Date(moment(new Date()).format("YYYY-MM-DD"))?.getTime()
                ? x?.date
                : "",
          };
        });
      setTimeout(() => {
        setRows([...updatedRecentSearchData]);
      }, 100);
      setClassValue(
        recentSearchedData?.selectedRecentSearchData?.class === "PE"
          ? t("label_economy_and_economy_plus")
          : t("label_business_and_first")
      );
      setPassengerCount({
        adult: recentSearchedData?.selectedRecentSearchData?.passengers?.adult,
        youth: recentSearchedData?.selectedRecentSearchData?.passengers?.youth,
        child: recentSearchedData?.selectedRecentSearchData?.passengers?.child,
        infant:
          recentSearchedData?.selectedRecentSearchData?.passengers?.infant,
      });
      const responseRecentSearchDesktop: any =
        window.localStorage.getItem("recentSearch");
      props?.setFormattedSearchFlightData &&
        props?.setFormattedSearchFlightData(
          JSON.parse(responseRecentSearchDesktop || "{}")
        );
    } else if (
      recentSearchedData?.selectedRecentSearchDataMobile &&
      isMobile &&
      recentSearchedData?.selectedRecentSearchDataMobile?.routes?.length > 0
    ) {
      setRows(recentSearchedData?.selectedRecentSearchDataMobile?.routes);
      setClassValue(
        recentSearchedData?.selectedRecentSearchDataMobile?.class === "PE"
          ? t("label_economy_and_economy_plus")
          : t("label_business_and_first")
      );
      setPassengerCount({
        adult:
          recentSearchedData?.selectedRecentSearchDataMobile?.passengers?.adult,
        youth:
          recentSearchedData?.selectedRecentSearchDataMobile?.passengers?.youth,
        child:
          recentSearchedData?.selectedRecentSearchDataMobile?.passengers?.child,
        infant:
          recentSearchedData?.selectedRecentSearchDataMobile?.passengers
            ?.infant,
      });
      const responseRecentSearchMobile: any =
        window.localStorage.getItem("recentSearch");
      props?.setFormattedSearchFlightData &&
        props?.setFormattedSearchFlightData(
          JSON.parse(responseRecentSearchMobile || "{}")
        );
    }
  }, [
    recentSearchedData?.selectedRecentSearchData,
    recentSearchedData?.selectedRecentSearchDataMobile,
  ]);

  useEffect(() => {
    const arrRecentSearch: Array<any> = [];
    for (let x = 0; x < rows.length; x++) {
      arrRecentSearch.push({
        id: `flight${x + 1}`,
        from: false,
        to: false,
        date: false,
      });
    }
    setRowsFocused(arrRecentSearch);
  }, [rows.length]);

  useEffect(() => {
    if (
      rows?.[0]?.to &&
      Object.keys(rows?.[0]?.to)?.length > 0 &&
      rows?.[1] &&
      Object.keys(rows?.[1]?.from)?.length === 0
    ) {
      const arr = [...rows];
      const tempObj = {
        ...arr[1],
      };
      const tempData = rows?.[1].from;
      tempObj.from =
        Object.keys(tempData)?.length === 0 &&
        Object.keys(rows[0]?.to)?.length > 0
          ? rows[0]?.to
          : "";
      arr[1] = tempObj;
      setRows([...arr]);
    }
  }, [Object.keys(rows?.[0]?.to)?.length]);

  useEffect(() => {
    if (airportData?.length > 0) {
      const airportListBySelectedCountry = airportData?.filter(
        (x: AirportData) =>
          x?.countryCode?.toLowerCase() ===
          i18n.language?.split("-")?.[1]?.toLowerCase()
      );
      const defaultAirport = airportListBySelectedCountry?.filter(
        (z: AirportData) => z.isDefault
      );
      const arr = [...rows];
      arr[0].from = defaultAirport?.[0];
      setRows(arr);
    }
  }, [airportData]);

  useEffect(() => {
    let cabinClassData;
    if (props?.isWidget) {
      if (!isEmptyChecker(props?.widgetParams?.params?.class)) {
        if (props?.widgetParams?.params?.class === CabinClass.Economy) {
          cabinClassData = t("label_economy_and_economy_plus");
        } else {
          cabinClassData = t("label_business_and_first");
        }
        setClassValue(cabinClassData);
      }
      if (
        !isEmptyChecker(props?.widgetParams?.params?.passengers) &&
        props?.widgetParams?.params?.passengers?.isRequired === "true"
      ) {
        let passengerData = {
          adult: props?.widgetParams?.params?.passengers?.adult?.value,
          youth: props?.widgetParams?.params?.passengers?.youth?.value,
          child: props?.widgetParams?.params?.passengers?.child?.value,
          infant: props?.widgetParams?.params?.passengers?.infant?.value,
        };
        setPassengerCount(passengerData);
      }
    }
  }, [props.isWidget]);

  const handleCabin = () => {
    if (classValue === t("label_economy_and_economy_plus")) {
      return "PE";
    } else return "FB";
  };

  const renderRecentSearchAirportCode = () => {
    let content = `${rows[0]?.from.airportCode}-${rows[0]?.to.airportCode}`;
    for (let x: number = 0; x + 1 < rows.length; x++) {
      if (rows[x].to?.airportCode === rows[x + 1]?.from?.airportCode) {
        content += `-${rows[x + 1]?.to.airportCode}`;
      } else
        content += `//${rows[x + 1]?.from.airportCode}-${
          rows[x + 1]?.to.airportCode
        }`;
    }
    return content;
  };

  const handleEnteredTransition = (selectedDate: any) => {
    if (selectedDate) {
      document
        .querySelectorAll(
          ".react-datepicker__day--selected:not(.react-datepicker__day--outside-month)"
        )[0]
        ?.parentNode?.parentNode?.parentNode?.scrollIntoView({
          block: "start",
        });
    } else {
      document
        .querySelectorAll(
          "[aria-disabled=false]:not(.react-datepicker__day--outside-month)"
        )?.[0]
        ?.parentNode?.parentNode?.parentNode?.scrollIntoView({
          block: "start",
        });
    }
  };

  const generateRandomId = () => {
    let minm = 10000;
    let maxm = 99999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  };

  const handleSubmit = () => {
    let str = "";
    for (let x: number = 0; x < rows.length; x++) {
      str += `${x !== 0 ? "&" : ""}d_airport${x + 1}=${
        rows[x]?.from?.airportCode
      }&a_airport${x + 1}=${rows[x]?.to?.airportCode}&d_date${x + 1}=${moment(
        rows[x]?.date
      ).format("YYYY-MM-DD")}`;
      const fromAirportList: any = recentAirports?.fromAirport;
      const toAirportList: any = recentAirports?.toAirport;
      const eleIndex =
        fromAirportList &&
        fromAirportList?.findIndex(
          (y: any) => y.airportCode === rows[x]?.from?.airportCode
        );
      const eleIndexToAirport =
        toAirportList &&
        toAirportList?.findIndex(
          (y: any) => y.airportCode === rows[x]?.to?.airportCode
        );
      if (eleIndex < 0) {
        fromAirportList.push(rows[x]?.from);
        if (fromAirportList?.length > 10) {
          fromAirportList?.shift();
        }
        setRecentAirports({ ...recentAirports, fromAirport: fromAirportList });
        setLocalStorageData("recentFromAirport", fromAirportList);
      }
      if (eleIndexToAirport < 0) {
        toAirportList.push(rows[x]?.to);
        if (toAirportList?.length > 10) {
          toAirportList?.shift();
        }
        setRecentAirports({ ...recentAirports, toAirport: toAirportList });
        setLocalStorageData("recentToAirport", toAirportList);
      }
    }
    str += `&trip_type=M&n_adult=${passengerCount?.adult}&n_youth=${
      passengerCount?.youth
    }&n_child=${passengerCount?.child}&n_infant=${
      passengerCount?.infant
    }&cabin_class=${handleCabin()}&lang=GB&source=web`;
    window.open(
      `${process.env.REACT_APP_TG_API_BASE_URL}pss_router/multi/booking?` + str,
      "_blank"
    );
    let recentSearchBottomData: any =
      window.localStorage.getItem("recentSearch");
    recentSearchBottomData = JSON.parse(recentSearchBottomData || "[]");
    const finalObj = {
      class: handleCabin(),
      count:
        passengerCount?.adult +
        passengerCount?.child +
        passengerCount?.youth +
        passengerCount?.infant,
      depart: moment(rows[0]?.date)?.format("YYYY-MM-DD"),
      from: { airportCode: renderRecentSearchAirportCode() },
      id: `multicity-${renderRecentSearchAirportCode()}-${generateRandomId()}`,
      passengers: {
        adult: passengerCount?.adult,
        youth: passengerCount?.youth,
        child: passengerCount?.child,
        infant: passengerCount?.infant,
      },
      return: moment(rows[rows?.length - 1]?.date)?.format("YYYY-MM-DD"),
      to: {},
      type: "Multi-city",
      url: "",
      routes: rows,
    };
    let existenseArr: any = [];
    const multiCityArr = recentSearchBottomData.filter(
      (obj: any) => obj.type === "Multi-city"
    );
    multiCityArr.forEach((x: any) => {
      if (
        JSON.stringify({ ...x, id: "01" }) ===
        JSON.stringify({ ...finalObj, id: "01" })
      ) {
        existenseArr.push(true);
      } else existenseArr.push(false);
    });
    if (existenseArr?.every((z: any) => !z)) {
      recentSearchBottomData.push(finalObj);
      if (!isMobile) {
        dispatch(updateSelectedCardData({ cardData: finalObj }));
      } else {
        dispatch(updateSelectedCardDataMobile({ cardData: finalObj }));
      }
      window.localStorage.setItem(
        "recentSearch",
        JSON.stringify(recentSearchBottomData)
      );
    }
  };

  const handleAddFlightRow = () => {
    if (rows.length < 6) {
      let tempRow = rows;
      tempRow = [
        ...tempRow,
        {
          id: `flight${rows.length + 1}`,
          from: rows[rows.length - 1]?.to || {},
          to: {},
          date: "",
        },
      ];
      setRows([...tempRow]);
      //focus case
      const tempRowFocused = rowsFocused;
      tempRowFocused.push({
        id: `flight${rowsFocused.length + 1}`,
        from: false,
        to: false,
        date: false,
      });
      setRowsFocused([...tempRowFocused]);
    }
    setAddFlightFocused(false);
    addFlightRef?.current?.blur();
  };

  const handleDeleteFlightRow = (i: any) => {
    let index = 0;
    const arr = rows?.map((x: any, ind: number) => {
      if (x.id !== i.id) {
        index += 1;
        return {
          id: `flight${index}`,
          from: x.from,
          to: x.to,
          date: x.date,
        };
      } else {
        index = ind;
        return null;
      }
    });
    const tempArr: any = arr?.filter((val: any) => val);
    setRows([...tempArr]);

    //focus case
    let focusedIndex = 0;
    const focusedArr = rowsFocused?.map((y: any, inde: number) => {
      if (y.id !== i.id) {
        focusedIndex += 1;
        return {
          id: `flight${focusedIndex}`,
          from: false,
          to: false,
          date: false,
        };
      } else {
        focusedIndex = inde;
        return null;
      }
    });
    const tempFocusedArr: any = focusedArr?.filter((val: any) => val);
    setRowsFocused([...tempFocusedArr]);
  };

  const handleSelect = (
    value: AirportData,
    fieldName: string,
    currentIndex: number
  ) => {
    let temp = {
      ...rows[currentIndex],
      [fieldName]: value,
    };
    if (fieldName === "from") {
      if (value?.airportCode === rows[currentIndex]?.to?.airportCode) {
        temp = { ...temp, to: {} };
      }
    } else if (fieldName === "to") {
      if (value?.airportCode === rows[currentIndex]?.from?.airportCode) {
        temp = { ...temp, from: {} };
      }
    }
    const tempArr = [...rows];
    tempArr[currentIndex] = temp;
    setRows([...tempArr]);

    //focus case
    const tempFocused = {
      ...rowsFocused[currentIndex],
      from:
        (fieldName === "from" && false) ||
        (fieldName === "to" &&
          rows[currentIndex]?.from?.airportCode === value?.airportCode),
      to: fieldName === "from",
      date:
        fieldName === "to" &&
        rows[currentIndex]?.from?.airportCode === value?.airportCode
          ? false
          : fieldName === "to",
    };
    const tempArrFocused = [...rowsFocused];
    tempArrFocused[currentIndex] = tempFocused;
    setRowsFocused([...tempArrFocused]);
  };

  const handleClear = (currentField: string, index: number) => {
    const temp = {
      ...rows[index],
      [currentField]: {},
    };
    const tempArr = [...rows];
    tempArr[index] = temp;
    setRows([...tempArr]);
  };

  const handleSelectedDate = (item: any, index: number) => {
    const temp = {
      ...rows[index],
      date: item?.stDate,
    };
    const tempArr = [...rows];
    tempArr[index] = temp;
    for (let x = index + 1; x < rows.length; x++) {
      const dateValue = item?.stDate >= tempArr[x].date ? "" : tempArr[x].date;
      const tempDateObj = {
        ...rows[x],
        date: dateValue,
      };
      tempArr[x] = tempDateObj;
    }
    setRows([...tempArr]);

    // focus case
    let tempArrFocused = [...rowsFocused];
    if (index + 1 < rowsFocused?.length) {
      const tempFocused = {
        ...rowsFocused[index + 1],
        from: true,
      };
      tempArrFocused[index + 1] = tempFocused;
    }
    if (index < rowsFocused?.length) {
      const tempCurrentFocused = {
        ...rowsFocused[index],
        date: false,
      };
      tempArrFocused[index] = tempCurrentFocused;
    }
    setRowsFocused([...tempArrFocused]);
    if (index === 5) {
      setIsPassengerFocused(true);
    } else if (index < 6) {
      setTimeout(() => {
        setAddFlightFocused(true);
      }, 200);
    }
  };

  const handleSwap = (index: number) => {
    if (
      Object.keys(rows[index]?.from)?.length > 0 &&
      Object.keys(rows[index]?.to)?.length > 0
    ) {
      const temp = {
        ...rows[index],
        from: rows[index]?.to,
        to: rows[index]?.from,
      };
      const tempArr = [...rows];
      tempArr[index] = temp;
      setRows([...tempArr]);
    }
  };

  const handleDropdownBlur = (indexNo: number, field: string) => {
    const tempFocused: any = [...rowsFocused];
    tempFocused[indexNo][field] = false;
    setRowsFocused([...tempFocused]);
  };

  const handleDateBlur = (idxN: number, obj: any) => {
    const temp = [...rowsFocused];
    temp[idxN] = { ...temp[idxN], ...obj };
    setRowsFocused([...temp]);
  };

  const handleMinDate = (indexN: number) => {
    if (indexN > 1) {
      if (
        rows[indexN - 1].date !== "" &&
        rows[indexN - 2].date !== "" &&
        moment(rows[indexN - 1].date).format("YYYY-MM-DD") ===
          moment(rows[indexN - 2].date).format("YYYY-MM-DD")
      ) {
        return moment(rows[indexN - 2].date).add(1, "day")["_d"];
      } else {
        const tempArr = [...rows];
        const arr = tempArr?.splice(0, indexN);
        let inde = arr.length;
        while (inde-- && !arr[inde]?.date);
        return arr[inde]?.date;
      }
    } else {
      return indexN - 1 >= 0 && rows[indexN - 1]?.date;
    }
  };
  const handleClearRecentSearch = (field: string) => {
    if (field === "from") {
      setRecentAirports({ ...recentAirports, fromAirport: [] });
    } else if (field === "to") {
      setRecentAirports({ ...recentAirports, toAirport: [] });
    }
  };

  const filterDuplicate = (selectedObj: any) => {
    const filteredOptionArr = airportData?.filter(
      (x: any) => x?.airportCode !== selectedObj?.airportCode
    );
    return filteredOptionArr;
  };

  const filterRecentAirportsDuplicate = (list: any, selectedObj: any) => {
    const filteredOptionArr = list?.filter(
      (x: any) => x?.airportCode !== selectedObj?.airportCode
    );
    return filteredOptionArr;
  };

  const renderFlightRow = (index: number, value: any) => {
    return (
      <div
        className={`${styles["multicity-flight-row-wrapper"]} d-flex flex-column w-100 gap-3`}
        key={index}
      >
        <div className="d-flex justify-content-between w-100">
          <span className={styles.flightText}>
            {t("label_flight_header")} {index + 1}
          </span>
          {rows?.length > 2 && (
            <span
              className={styles.flightText}
              onClick={() => handleDeleteFlightRow(value)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0003 2.29169C5.74313 2.29169 2.29199 5.74283 2.29199 10C2.29199 14.2572 5.74313 17.7084 10.0003 17.7084C14.2575 17.7084 17.7087 14.2572 17.7087 10C17.7087 5.74283 14.2575 2.29169 10.0003 2.29169ZM1.04199 10C1.04199 5.05247 5.05277 1.04169 10.0003 1.04169C14.9479 1.04169 18.9587 5.05247 18.9587 10C18.9587 14.9476 14.9479 18.9584 10.0003 18.9584C5.05277 18.9584 1.04199 14.9476 1.04199 10ZM7.05838 7.05808C7.30246 6.814 7.69819 6.814 7.94227 7.05808L10.0003 9.11614L12.0584 7.05808C12.3025 6.814 12.6982 6.814 12.9423 7.05808C13.1863 7.30216 13.1863 7.69789 12.9423 7.94196L10.8842 10L12.9423 12.0581C13.1863 12.3022 13.1863 12.6979 12.9423 12.942C12.6982 13.186 12.3025 13.186 12.0584 12.942L10.0003 10.8839L7.94227 12.942C7.69819 13.186 7.30246 13.186 7.05838 12.942C6.81431 12.6979 6.81431 12.3022 7.05838 12.0581L9.11644 10L7.05838 7.94196C6.81431 7.69789 6.81431 7.30216 7.05838 7.05808Z"
                  fill="#6A6D87"
                />
              </svg>
            </span>
          )}
        </div>
        <div className={`${styles["multicity-main-container"]} d-flex gap-3`}>
          <div className={styles["multicity-destination-container"]}>
            <MultiCityAutosuggest
              label={t("label_book_widget_from")}
              customClassName="fromDropdownBorderRadius"
              onChange={() => {}}
              onSelect={handleSelect}
              searchPlaceHolder={t("label_multi_city_search_placeholder")}
              name="from"
              options={airportData}
              rowIndex={index}
              selectedPropsValue={rows[index]?.from}
              onClear={(i: number) => handleClear("from", i)}
              reverseArrow={true}
              onSwap={handleSwap}
              recentAirport={filterRecentAirportsDuplicate(
                recentAirports?.fromAirport,
                rows[index]?.to
              )?.reverse()}
              onClearRecentSearch={handleClearRecentSearch}
              isFocused={rowsFocused[index]?.from}
              onAutoSuggestBlur={() => handleDropdownBlur(index, "from")}
            />
            <MultiCityAutosuggest
              label={t("label_book_widget_to")}
              customClassName="toDropdownBorderRadius"
              onChange={() => {}}
              onSelect={handleSelect}
              searchPlaceHolder={t("label_multi_city_search_placeholder")}
              name="to"
              options={airportData}
              rowIndex={index}
              selectedPropsValue={rows[index]?.to}
              onClear={(i: number) => handleClear("to", i)}
              recentAirport={filterRecentAirportsDuplicate(
                recentAirports?.toAirport,
                rows[index]?.from
              )?.reverse()}
              onClearRecentSearch={handleClearRecentSearch}
              isFocused={rowsFocused[index]?.to}
              onAutoSuggestBlur={() => handleDropdownBlur(index, "to")}
            />
          </div>
          <div className={styles["multicity-date-container"]}>
            <TGDateCalendar
              label={t("label_book_widget_depart")}
              onChange={(e, label) => {}}
              type="1way"
              dateData={{ stDate: value?.date }}
              setDateData={(val: any) => handleSelectedDate(val, index)}
              onReset={() => {}}
              focused={{ date: rowsFocused[index]?.date }}
              setFocused={() => {}}
              minDate={handleMinDate(index)}
              name="multicity"
              onDateBlur={(obj: any) => handleDateBlur(index, obj)}
              onEnter={() => handleEnteredTransition(value?.date)}
              hideFare={true}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {rows?.map((x: any, index: number) => {
        return renderFlightRow(index, x);
      })}
      {rows?.length < 6 && (
        <button
          className={`${styles["multi-city-add-flight"]} mb-4 d-flex justify-content-start align-items-center`}
          onClick={handleAddFlightRow}
          aria-hidden="true"
          ref={addFlightRef}
          onBlur={() => setAddFlightFocused(false)}
          disabled={
            !rows?.every(
              (x: any) =>
                x?.from &&
                Object.keys(x?.from)?.length > 0 &&
                x?.to &&
                Object.keys(x?.to)?.length > 0 &&
                x?.date !== ""
            )
          }
        >
          <TGIconLabel
            icon="plus-icon"
            label={t("button_add_flight")}
            position="left"
            optionalClassName="containerBorder"
          />
        </button>
      )}
      {rows?.length === 6 && isMobile && (
        <div className={styles["multi-city-max-limit"]}>
          {t("label_multi_city_flight_limit")}
        </div>
      )}
      <div
        className={`${styles["multi-city-end-wrapper"]} d-flex justify-content-between`}
      >
        <div className={styles["multi-city-passenger-wrapper"]}>
          <div className={styles["multi-city-passenger-popup-wrapper"]}>
            <PassengerClass
              handleCountSelection={() => {}}
              handleRadioChange={() => {}}
              focused={{ pax: isPassengerFocused }}
              setFocused={() => {
                setIsPassengerFocused(false);
              }}
              counter={passengerCount}
              setCounter={setPassengerCount}
              classData={classValue}
              setClassData={setClassValue}
              handleReset={() => setIsPassengerFocused(false)}
              sourceAirport={rows[0].from}
              tripType="multicity"
              country={rows[0]?.from}
              isWidget={props?.isWidget}
              widgetParams={props?.widgetParams}
            />
          </div>
          <div className={styles["multi-city-promo-code"]}>
            <TGIconLabel
              icon="plus-icon"
              label={t("label_book_widget_code")}
              position="left"
              optionalClassName="containerBorder"
            />
          </div>
        </div>
        <TGButton
          label={t("button_search_flights")}
          variant="primary"
          buttonSize="lg"
          customClass="bookingButtonContainer"
          onClick={handleSubmit}
          disabled={
            !rows?.every(
              (x: any) =>
                x?.from &&
                Object?.keys(x?.from).length > 0 &&
                x?.to &&
                Object?.keys(x?.to)?.length > 0 &&
                x?.date !== ""
            )
          }
        />
      </div>
    </>
  );
};

export default MultiCity;
